import * as React from "react"
const AboutPage = () => {
  return (
    <main>
      <title>About</title>
      <h1>Why Startup Trail Guide?</h1>
      <p>Startup Trail Guide started as a way for me to organize the information
        I found useful about building successful businesses.</p>
      <h1>About Me</h1>
      <p>In the beginning, I was a software developer and loved it. As I gained
        more experience I grew intersted in the business behind the software
        I was developing and started to experiment with building my own businesses.
      </p>
    </main >
  )
}

export default AboutPage
